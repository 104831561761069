<template>
  <v-container>
    <!-- HEADING -->
    <h1 class="default-bottom-margin">Impressum</h1>

    <!-- IMPRESSUM DATA -->
    <v-container>
      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
      <p>
        Julian Scheer<br />
        Stolbergerstra&szlig;e 229<br />
        52068 Aachen
      </p>
      <h2>Kontakt</h2>
      <p>
        Telefon: 01522 1653214<br />
        E-Mail: contact@synthetic-sympathy.de
      </p>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'Impressum',
  metaInfo: {
    meta: [
      { name: 'description', content: 'Impressum der Webseite des Musikprojekts Synthetic Sympathy.'},
      { name: 'robots', content: 'noindex, follow'}
    ]
  },
}
</script>